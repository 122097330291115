.my-gallery-class{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-img{
  border: 10px solid black;
  gap: 10px;
  margin: 10px;
}

#modal-img{
  width: 1024px;
  height: 1024px;
}
